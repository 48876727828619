/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-05-05 10:44:30
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-05-06 14:30:58
 */
import { enumToOption } from '@/utils'
import { ElTag } from 'element-plus'
import { h } from 'vue'

export enum isCompel {
  强制付费 = 1,
  可暂不付费
}

export const isCompelOption = enumToOption(isCompel)

// import { enumToOption } from '@/utils/index'
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '编号',
    // table index索引属性或方法 number, Function(index) 注意可以赋值 number 或是传入一个 function
    indexMethod: 1,
    // table 工具设置选项 配置则默认开启
    tool: null,
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 100,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'uuid',
      label: '套餐编号',
      disabledHidden: true,
      width: '90'
    },
    {
      key: 'title',
      label: '套餐标题',
      disabledHidden: true,
      width: '90'
    },
    {
      key: 'roleName',
      label: '试用功能权限',
      disabledHidden: true,
      width: '120'
    },
    {
      key: 'newRoleName',
      label: '新功能版本',
      width: '120'
    },
    {
      key: 'trialPeriod',
      label: '试用天数',
      disabledHidden: true,
      width: '90'
    },
    {
      key: 'formalPeriod',
      label: '正式期（天)',
      disabledHidden: true,
      width: '110'
    },
    {
      key: 'carBasicAnnualFee',
      label: '单车基础年费',
      width: '110'
    },
    {
      key: 'price',
      label: '单车费用（天/元)',
      width: '140'
    },
    {
      key: 'carChargeAnnualFee',
      label: '单车收费年费',
      width: '140'
    },

    {
      key: 'carChargeDailyFee',
      label: '单车收费（天/元）',
      width: '140'
    },
    {
      key: 'withholdingRate',
      label: '代扣费率',
      width: '110',
      formatter: (row, col, val) => { return val || '-' }
    },
    {
      key: 'basicAnnualFee',
      label: '基础年费',
      width: '90'
    },
    {
      key: 'description',
      label: '描述',
      width: '90'
    },
    {
      key: 'isCompel',
      label: '试用结束后',
      width: '90',
      formatter: (row, col, val) => { return isCompel[val] || '-' }
    },
    {
      key: 'createTime',
      label: '创建时间',
      width: '140'
    },
    {
      key: 'enabled',
      label: '状态',
      width: '90',
      formatter: (row, col, val) => {
        return h(ElTag, { size: 'medium', type: val === 1 ? 'success' : 'danger' }, val === 0 ? '禁用' : '启用')
      }
    },
    {
      key: 'action',
      label: '操作',
      width: '140',
      fixed: 'right',
      disabledHidden: true,
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 状态
export enum statusEnum {
  启用= 1,
  禁用 = 0
}
// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'input',
      label: '套餐编号',
      key: 'uuid',
      placeholder: '请输入',
      labelWidth: 110,
      inputWidth: 200
    },
    {
      type: 'input',
      label: '标题',
      key: 'title',
      placeholder: '请输入',
      labelWidth: 110,
      inputWidth: 200
    },
    {
      type: 'select',
      label: '状态',
      key: 'enabled',
      placeholder: '请选择',
      labelWidth: 110,
      inputWidth: 200,
      options: enumToOption(statusEnum),
      rules: {
        type: 'number'
      }
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    currPage: 1,
    pageSize: 20,
    total: 0,
    visible: false, // 弹框显示
    title: '添加购置税',
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
