/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-05-05 10:46:13
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-05-05 17:52:04
 */

import service from '@/utils/request'
const API = {
  LIST_PATH: '/serve/combo/page', // 获取列表
  ADD_PATH: '/serve/combo/add', // 新增
  UPDATE_PATH: 'serve/combo/update', // 修改
  ROLE_PATH: '/admin/preset/role/list' // 功能下拉
}
export const getListAPI = (params: any) =>
  service.request({
    method: 'post',
    url: API.LIST_PATH,
    data: params
  })
export const setAddAPI = (params: any) =>
  service.request({
    method: 'post',
    url: API.ADD_PATH,
    data: params
  })
// // 获取预置角色下拉框
export const getRoleList = (params: any) =>
  service.request({
    method: 'post',
    url: API.ROLE_PATH,
    data: params
  })
// 修改状态
export const setUpdateAPI = (params: any) =>
  service.request({
    method: 'post',
    url: API.UPDATE_PATH,
    data: params
  })
