
import CTable from '@/components/c-table'
// import ModifyDialog from './add-modal.vue'
// import detailModal from './detail-modal.vue'
import { getTableConfig, getIndexConfig } from './config'
import CSearchList from '@/components/c-search-list'
import { ElMessage, ElMessageBox } from 'element-plus'
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue'
import { getListAPI, setUpdateAPI } from './api'
import { btnName } from '@/utils/index'
import { toResult } from '@/utils/toResult'

export default defineComponent({
  components: {
    CSearchList,
    CTable,
    dialogs: defineAsyncComponent(() => import('./src/dialog.vue'))
  },

  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })
    // 页面基础变量
    const data = reactive({
      isDialog: false,
      ...getIndexConfig()
    })
    // 列表数据
    const getListFn = async () => {
      const params = {
        currPage: data.currPage,
        pageSize: data.pageSize
      }
      tableConfig.loading = true
      const obj = Object.assign(params, data.queryForm)
      const [err, res] = await toResult(getListAPI(obj))
      tableConfig.loading = false
      if (err) return
      tableConfig.data = res.list || []
      tableConfig.pagination.total = res.total || 0
    }

    // 表单设置页数
    const pageSizeChange = (val: number) => {
      data.pageSize = val
      getListFn()
    }

    // 表单翻页
    const pageCurrentChange = (val: number) => {
      data.currPage = val
      getListFn()
    }

    // 执行搜索
    const handleSearch = (params: object) => {
      data.queryForm = params
      getListFn()
    }
    // 执行重置
    const handleReset = (params: object) => {
      data.queryForm = params
      getListFn()
    }
    const handleAction = (type: number, row: any) => {
      const [desc, tit] = [`确定${type === 0 ? '禁用' : '启用'}？`, '提示']
      ElMessageBox.confirm(desc, tit)
        .then(async () => {
          const obj = Object.assign(
            {},
            {
              uuid: row.uuid,
              enabled: type
            }
          )
          const [err, res, msg] = await toResult(setUpdateAPI(obj))
          if (err) return false
          ElMessage.success(msg)
          getListFn()
          console.log(res)
        })
        .catch(() => {})
    }
    // 表单操作栏按钮
    const btnHandle = (name: string, row?: any) => {
      data[name] = !data[name]
      data.rowData = Object.assign({}, row)
    }

    return {
      ...toRefs(data),
      btnName,
      handleSearch,
      handleReset,
      getListFn,
      tableConfig,
      btnHandle,
      handleAction,
      pageCurrentChange,
      pageSizeChange
    }
  },
  mounted() {
    this.getListFn()
  }
})
